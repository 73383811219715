import { api, uploadApi } from '@/api/api'
import * as types from '../../mutation-types'
import CustomerService from '@/services/customer/customer.service'

const state = {
  user: {
    id: '',
    firstName: '',
    lastName: '',
    fullName: '',
    avatar: '',
    customers: []
  },
  users: [],
  purchasers: [],
  procurementLeads: []
}

export const getters = {
  getLoggedInUser: (state) => state.user,
  getUsers: (state) => state.users,
  getPurchasers: (state) => state.purchasers,
  getProcurementLeads: (state) => state.procurementLeads,
  getUser: (state) => (userId) => {
    return state.users.find((u) => u.id === userId)
  },
  getIsSiteAdmin: (state) => {
    return (
      state.user.customers &&
      state.user.customers.length > 0 &&
      state.user.customers.some((c) => c.id === import.meta.env.VITE_ESOL_CUST_ID)
    )
  }
}
export const actions = {
  setLoggedInUser: ({ commit }, user) => {
    commit(types.USER_SET, user)
  },
  fetchUser: ({ commit }, userId) => {
    const url = `/api/v1/customer/{customerId}/Users/?userId=${userId}&filter.fields=permissions`
    return api.get(url).then((response) => {
      const user = response.data.data.userDTO.values[0]
      commit(types.USER_SET, user)
      return user
    })
  },
  fetchUsers: async ({ commit, rootGetters }, filters) => {
    const users = await CustomerService().getUsers({ filters })
    // We have to populate the groups list from the store for v2
    if (rootGetters.getFeatureEnabled('api2Customer').enabled) {
      const groups = rootGetters.getGroups

      users.forEach((user) => {
        if (user.groupIds !== null && user.groupIds.length > 0) {
          user.userGroups = groups.filter((g) => user.groupIds.indexOf(g.id) > -1)
        }
      })
    }

    commit(types.USER_SET_ALL, users)
    return users
  },
  fetchPurchasers: ({ commit }, filter) => {
    let url = '/api/v1/customer/{customerId}/Users?isPurchaser=true'
    if (filter != null && filter.length > 0) {
      url += `&filter.fields=${filter}`
    }
    return api.get(url).then((response) => {
      const data = response.data.data.userDTO.values
      commit(types.USER_SET_PURCHASERS, data)
      return data
    })
  },
  updateLoggedInUser: ({ commit }, user) => {
    // updates the currently logged-in user
    return api.put(`/api/v1/customer/{customerId}/Users`, user).then((response) => {
      const data = response.data.data.userDTO.values
      commit(types.USER_SET_ALL, data)
      return data
    })
  },
  updateUser: ({ commit, rootGetters }, user) => {
    // updates a different user, checking the logged-in user's permissions first
    const loggedInUser = rootGetters['getLoggedInUser']?.id || ''
    const url = `api/v1/customer/{customerId}/Users/${loggedInUser}/teamMember`

    return api.put(url, user).then((response) => {
      const data = response.data.data.userDTO.values
      commit(types.USER_SET_ALL, data)
      return data
    })
  },
  unlockUser: ({ commit }, userId) => {
    return api
      .put(`/api/v1/customer/{customerId}/Users/Unlock?userid=${userId}`)
      .then((response) => {
        const data = response.data.data.userDTO.values
        commit(types.USER_SET_ALL, data)
        return data
      })
  },
  updateUserStatus: ({ commit }, params) => {
    const userIds = params.users.map((u) => u.id)

    return api
      .put(
        `/api/v1/customer/{customerId}/Users/Status?active=${params.status}&userId=${userIds.join(
          ','
        )}`
      )
      .then((response) => {
        const data = response.data.data.userDTO.values
        commit(types.USER_SET_ALL, data)
        return data
      })
  },
  createUser: ({ commit }, user) => {
    return api.post(`/api/v1/customer/{customerId}/Users`, user).then((response) => {
      const data = response.data.data.userDTO.values
      commit(types.USER_SET_ALL, data)
      return data
    })
  },
  updateUsersGroupAssociations: ({ commit }, group) => {
    commit(types.USER_REPLACE_USERS_GROUP, group)
  },
  deleteUsersGroupAssociations: ({ commit }, groupId) => {
    commit(types.USER_DELETE_USERS_GROUP, groupId)
  },
  updateAvatar: (_, payload) => {
    const { userId, formData } = payload
    const avatarRoute = `/api/v1/customer/{customerId}/Users/Upload?userId=${userId.toUpperCase()}`
    return uploadApi.post(avatarRoute, formData)
  }
}
export const mutations = {
  [types.USER_SET](state, user) {
    state.user.id = user.id
    state.user.firstName = user.firstName
    state.user.lastName = user.lastName
    state.user.fullName = `${user.firstName} ${user.lastName}`
    state.user.avatar =
      user.avatarUrl ||
      `https://ui-avatars.com/api/?size=64&name=${user.firstName}+${user.lastName}`
    state.user.inActive = user.inActive
    state.user.purchaser = user.purchaser
    state.user.userGroups = user.userGroups
    state.user.customers = user.customers
    state.user.username = user.username
    state.user.permissions = user.permissions
    state.user.proPermissions = user.proPermissions
    state.user.email = user.email
  },
  [types.USER_CREATE](state, user) {
    state.user.id = user.id
    state.user.firstName = user.firstName
    state.user.lastName = user.lastName
    state.user.fullName = `${user.firstName} ${user.lastName}`
    state.user.avatar =
      user.avatarUrl ||
      `https://ui-avatars.com/api/?size=64&name=${user.firstName}+${user.lastName}`
    state.user.inActive = user.inActive
    state.user.purchaser = user.purchaser
    state.user.userGroups = user.userGroups
  },
  [types.USER_SET_ALL](state, users) {
    // TODO - this should not be required. State storage of entities should be consistent
    users.forEach((u) => {
      u.avatarUrl =
        u.avatarUrl == null ||
        u.avatarUrl ===
          `https://service-nst.bidsandtenders.ca/resources/images/avatar/https://ui-avatars.com/api/?size=64&name=${u.firstName}+${u.lastName}`
          ? `https://ui-avatars.com/api/?size=64&name=${u.firstName}+${u.lastName}`
          : u.avatarUrl
    })
    // TODO -same as above we need to standardize the naming for objects in NEXT to objects from the DTOS
    state.users = users
  },
  [types.USER_SET_PURCHASERS](state, users) {
    if (users && users.length > 0) {
      users.sort((a, b) => a.firstName.localeCompare(b.firstName, 'en', { sensitivity: 'base' }))
      state.purchasers = users.filter((u) => u.purchaser)
      const updatedProcurementLeads = [...users]
      state.procurementLeads = updatedProcurementLeads.filter((g) => g.inActive === false)
    }
  },

  [types.USER_ADD_GROUPS](state, payload) {
    const groups = payload.groups.filter((g) => payload.groupIds.split(',').indexOf(g.id) > -1)
    const users = state.users.filter((u) => payload.userIds.split(',').indexOf(u.id) > -1)

    users.forEach((user) => {
      groups.forEach((group) => {
        if (user.userGroups === null) {
          user.userGroups = []
        } else if (user.userGroups.filter((g) => g.id === group.id).length === 0) {
          user.userGroups.push({
            id: group.id,
            name: group.name
          })
        }
      })
    })
  },
  [types.USER_REMOVE_GROUPS](state, payload) {
    const groups = payload.groups.filter((g) => payload.groupIds.split(',').indexOf(g.id) > -1)
    const users = state.users.filter((u) => payload.userIds.split(',').indexOf(u.id) > -1)

    users.forEach((user) => {
      const userGroupIds = user.userGroups.map((g) => g.id)
      groups.forEach((group) => {
        if (user.userGroups == null) {
          user.userGroups = []
        } else {
          const groupIndex = userGroupIds.indexOf(group.id)
          if (groupIndex >= 0) {
            user.userGroups.splice(groupIndex, 1)
          }
        }
      })
    })
  },
  [types.USER_REPLACE_USERS_GROUP](state, group) {
    state.users.forEach((user) => {
      if (user.userGroups != null && user.userGroups.length > 0) {
        const groupMatchIndex = user.userGroups.map((g) => g.id).indexOf(group.id)
        if (groupMatchIndex >= 0) {
          user.userGroups[groupMatchIndex] = group
        }
      }
    })
  },
  [types.USER_DELETE_USERS_GROUP](state, groupId) {
    state.users.forEach((user) => {
      if (user.userGroups != null && user.userGroups.length > 0) {
        const groupMatchIndex = user.userGroups.map((g) => g.id).indexOf(groupId)
        if (groupMatchIndex >= 0) {
          user.userGroups.splice(groupMatchIndex, 1)
        }
      }
    })
  }
}
export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
